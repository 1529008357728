import { Avatar, Flex, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@fscrypto/ui";
import { useCopyWorkItem } from "../actor";
import invariant from "tiny-invariant";
import { useCurrentUser } from "~/features/current-user";
import { useProfileManager } from "~/features/profile-manager/state";

export const TeamSelector = () => {
  const { teams = [] } = useProfileManager();
  const { selectContext } = useCopyWorkItem();
  const { currentUser } = useCurrentUser();
  const currentContext = currentUser?.currentProfileId === currentUser?.profileId ? "user" : "team";
  return (
    <div className="p-3">
      <Select
        onValueChange={(id) => {
          if (currentContext === "team" && id === currentUser?.id) {
            selectContext?.(currentUser);
          } else {
            const team = teams.find((team) => team.id === id);
            invariant(team, "team must be selected");
            selectContext?.(team);
          }
        }}
      >
        <SelectTrigger className="w-full">
          <SelectValue placeholder="Select a Context" />
        </SelectTrigger>
        <SelectContent onSelect={(e) => e.preventDefault()}>
          {currentContext !== "user" && (
            <SelectItem key={currentUser?.id} value={currentUser?.id ?? ""}>
              <Flex gapX={2}>
                <Avatar src={currentUser?.avatarUrl} size="md" />
                {currentUser?.username}
              </Flex>
            </SelectItem>
          )}
          {teams
            .filter((item) => item.profileId !== currentUser?.currentProfileId)
            .map((team) => (
              <SelectItem key={team.id} value={team.id}>
                <Flex gapX={2}>
                  <Avatar src={team.avatarUrl} size="sm" />
                  {team.name}
                </Flex>
              </SelectItem>
            ))}
        </SelectContent>
      </Select>
    </div>
  );
};
