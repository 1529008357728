import { Profile } from "@fscrypto/domain/profile";
import { createSimpleEpic } from "@fscrypto/state-management";
import { type Observable, merge } from "rxjs";
import { currentUserClient } from "../../data/current-user-client";
import { UserUpdate } from "@fscrypto/domain/user";

// Input events, triggers epics
type SetDefaultWarehouse = {
  type: "CURRENT_USER.EPIC.UPDATE_WAREHOUSE";
  payload?: Profile;
};

type UpdateProfile = {
  type: "CURRENT_USER.EPIC.UPDATE_USER";
  payload: {
    data: UserUpdate;
    userId?: string;
  };
};

// Output events, sent to the machine
type SetDefaultWarehouseSuccess = { type: "CURRENT_USER.EPIC.UPDATE_WAREHOUSE_SUCCESS"; payload: Profile };
type SetDefaultWarehouseFailure = { type: "CURRENT_USER.EPIC.UPDATE_WAREHOUSE_FAILURE"; payload: Error };

type UpdateProfileSuccess = { type: "CURRENT_USER.EPIC.UPDATE_USER_SUCCESS"; payload: Profile };
type UpdateProfileFailure = { type: "CURRENT_USER.EPIC.UPDATE_USER_FAILURE"; payload: Error };

export type OutputEvent =
  | SetDefaultWarehouseSuccess
  | SetDefaultWarehouseFailure
  | UpdateProfileSuccess
  | UpdateProfileFailure;

type InputEvent = SetDefaultWarehouse | UpdateProfile;

/**
 * Merges multiple epic functions into a single Observable<OutputEvent>.
 * Merging these epics into one observable allows using a single subject for capturing all events.
 */
export const createEpic = (actions$: Observable<InputEvent>): Observable<OutputEvent> => {
  return merge(removeWorkItemEpic(actions$), updateProfileEpic(actions$));
};

const removeWorkItemEpic = createSimpleEpic<
  SetDefaultWarehouse,
  Profile,
  SetDefaultWarehouseSuccess,
  SetDefaultWarehouseFailure
>({
  ofType: "CURRENT_USER.EPIC.UPDATE_WAREHOUSE",
  performTask: async (payload) => currentUserClient.updateDefaultWarehouse(payload),
  onSuccess: (payload) => ({ type: "CURRENT_USER.EPIC.UPDATE_WAREHOUSE_SUCCESS", payload }),
  onFailure: (error) => ({
    type: "CURRENT_USER.EPIC.UPDATE_WAREHOUSE_FAILURE",
    payload: new Error("Unable to Update Default Warehouse"),
  }),
});

const updateProfileEpic = createSimpleEpic<UpdateProfile, Profile, UpdateProfileSuccess, UpdateProfileFailure>({
  ofType: "CURRENT_USER.EPIC.UPDATE_USER",
  performTask: async (payload) => currentUserClient.updateProfile(payload),
  onSuccess: (payload) => ({ type: "CURRENT_USER.EPIC.UPDATE_USER_SUCCESS", payload }),
  onFailure: (error) => ({
    type: "CURRENT_USER.EPIC.UPDATE_USER_FAILURE",
    payload: new Error("Unable to Update Profile"),
  }),
});

// ASYNC

// Add any async calls here
