import { useLocation } from "@remix-run/react";
import { useEffect, useRef } from "react";
import type { CurrentUser } from "@fscrypto/domain";
import { identify, page, load, tracking } from "./tracking";
import { setUser } from "@sentry/browser";
import { Subject, debounceTime } from "rxjs";
import { useObservable, useSubscription } from "observable-hooks";

export const useTrackingInitialize = (currentUser?: CurrentUser) => {
  const location = useLocation();

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    currentUser && identify(currentUser);
    // Set Sentry User
    const user = {
      id: currentUser?.id,
      email: currentUser?.email,
      username: currentUser?.username,
    };

    setUser(currentUser ? user : null);
  }, [currentUser]);

  useEffect(() => {
    page(location.pathname);
  }, [location.pathname]);
};

const mutation$$ = new Subject<void>();

export const useExternalLinkTracking = () => {
  const existingLinks = useRef<Record<string, { el: HTMLElement; handler: () => void }>>({});
  const mutation$ = useObservable(() => mutation$$.pipe(debounceTime(500)));
  useSubscription(mutation$, () => {
    const links = Array.from(document.querySelectorAll("a"));
    const externalLinks = links.filter(
      (link) => link.href && !link.href.includes(window.ENV.BASE_URL) && !link.href.includes("flipsidecrypto.xyz"),
    );
    const newLinks = externalLinks.filter((link) => !existingLinks.current[link.href]);
    const linksToRemove = Object.keys(existingLinks.current).filter(
      (link) => !externalLinks.find((el) => el.href === link),
    );
    if (newLinks.length === 0 && linksToRemove.length === 0) {
      return;
    }
    newLinks.forEach((link) => {
      const handler = () => {
        tracking("external_link_click", "", { external_url: link.href });
      };
      link.addEventListener("click", handler);
      existingLinks.current[link.href] = { el: link, handler };
    });
    linksToRemove.forEach((link) => {
      existingLinks.current[link]?.el.removeEventListener("click", existingLinks.current[link].handler);
      delete existingLinks.current[link];
    });
  });
  useEffect(() => {
    const observer = new MutationObserver(() => {
      mutation$$.next();
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: false,
      characterData: false,
    });
    return () => observer.disconnect();
  }, []);
};
