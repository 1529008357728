import { Profile } from "@fscrypto/domain/profile";
import { UserUpdate } from "@fscrypto/domain/user";
import { HttpClient, Client } from "@fscrypto/http";
import { $path } from "remix-routes";
import invariant from "tiny-invariant";

class ClientCurrentUser {
  constructor(private readonly client: HttpClient) {
    this.client = client;
  }

  async updateDefaultWarehouse(profile?: Profile): Promise<Profile> {
    invariant(profile, "Profile is required");
    const result = await this.client.post<Profile>($path("/api/profiles/:id/update", { id: profile.id }), {
      defaultWarehouseType: profile.defaultWarehouseType,
    });
    return result;
  }
  async updateProfile({ data, userId }: { data: UserUpdate; userId?: string }): Promise<Profile> {
    invariant(userId, "Data is required");
    const result = await this.client.post<Profile>($path("/api/users/:id/update", { id: userId }), data);
    return result;
  }
}

export const currentUserClient = new ClientCurrentUser(new Client());
