import { type Observable, merge, map, switchMap, from, catchError, of, interval } from "rxjs";
import { GET } from "~/async/fetch";

// Input events, triggers epics
type GetVersion = { type: "VERSION_LISTENER.EPIC.GET_VERSION" };

// Output events, sent to the machine
type GetVersionSuccess = { type: "VERSION_LISTENER.EPIC.GET_VERSION_SUCCESS"; payload: { version: string } };
type GetVersionFailure = { type: "VERSION_LISTENER.EPIC.GET_VERSION_FAILURE"; error: Error };

export type OutputEvent = GetVersionSuccess | GetVersionFailure;
type InputEvent = GetVersion;

/**
 * Merges multiple epic functions into a single Observable<OutputEvent>.
 * Merging these epics into one observable allows using a single subject for capturing all events.
 */
export const createEpic = (actions$: Observable<InputEvent>): Observable<OutputEvent> => {
  return merge(periodicVersionCheckEpic());
};

const timeToCheck = 15 * 60 * 1000;

const periodicVersionCheckEpic = (): Observable<OutputEvent> => {
  // Use interval to make a checkVersion request periodically
  return interval(timeToCheck).pipe(
    switchMap(() => {
      return from(checkVersion()).pipe(
        map(
          (payload) =>
            ({
              type: "VERSION_LISTENER.EPIC.GET_VERSION_SUCCESS",
              payload,
            }) as GetVersionSuccess,
        ),
        catchError((_) =>
          of({
            type: "VERSION_LISTENER.EPIC.GET_VERSION_FAILURE",
            error: new Error("Unable to claim reward"),
          } as GetVersionFailure),
        ),
      );
    }),
  );
};

// ASYNC

// Add any async calls here
async function checkVersion() {
  const response = await GET<{ version: string }>("/version");
  return response;
}
