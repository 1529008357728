import { createMachine } from "xstate";
import type { OutputEvent as EpicEvent } from "./epic";

interface Context {
  version?: string;
}

// A union of all events externally sent to the actor
type RefEvent = { type: "VERSION_LISTENER.FOO"; payload: string };
type Event = RefEvent | EpicEvent;

// They key pattern that these actors can be referenced by
// If only one actor of this type will exist, you can remove the -${string}
export type SystemKey = `versionListener`;

export const createVersionListenerMachine = (id: SystemKey) => {
  return createMachine({
    id,
    tsTypes: {} as import("./machine.typegen").Typegen0,
    predictableActionArguments: true,
    schema: {
      context: {} as Context,
      events: {} as Event,
    },
    initial: "idle",
    context: {},
    states: {
      idle: {
        on: {
          "VERSION_LISTENER.EPIC.GET_VERSION_SUCCESS": [
            {
              actions: "setVersion",
              cond: "isEmpty",
            },
            {
              actions: ["setVersion", "handleVersionChange"],
              cond: "isDifferent",
            },
          ],
        },
      },
    },
  });
};

export type Options = Parameters<ReturnType<typeof createVersionListenerMachine>["withConfig"]>["0"];
