import { createActor } from "~/state/actor";
import { createCurrentUserMachine, type Options } from "./machine";
import { createEpic } from "./epic";
import { actorSystem } from "~/state";
import { assign } from "xstate";

export const createCurrentUserActor = createActor(
  { createMachine: createCurrentUserMachine, createEpic },
  ({ epic, eventBus }): Options => ({
    actions: {
      updateUser: assign((context, event) => {
        if (!context.currentUser) return context;
        return {
          currentUser: { ...context.currentUser, ...event.payload },
        };
      }),
      updateUserEpic: (ctx, e) =>
        epic.send({
          type: "CURRENT_USER.EPIC.UPDATE_USER",
          payload: {
            data: e.payload,
            userId: ctx.currentUser?.id,
          },
        }),
      setDefaultWarehouse: assign((ctx, e) => {
        if (!ctx.profile) return ctx;
        return {
          profile: { ...ctx.profile, defaultWarehouseType: e.payload },
        };
      }),
      updateDefaultWarehouseEpic: (ctx, e) =>
        epic.send({
          type: "CURRENT_USER.EPIC.UPDATE_WAREHOUSE",
          payload: ctx.profile,
        }),
      toastSuccess: (ctx, e) =>
        eventBus.send({
          type: "TOAST.NOTIFY",
          notif: { title: "Success!", type: "success", description: "Saved user data" },
        }),
      toastFailure: () =>
        eventBus.send({
          type: "TOAST.NOTIFY",
          notif: { title: "Error!", type: "error", description: "Failed to save user data" },
        }),
      setProfileId: assign((context, event) => {
        if (!context.currentUser) return context;
        return {
          currentUser: { ...context.currentUser, currentProfileId: event.payload.profileId },
        };
      }),
    },
  }),
);

export type CurrentUserActor = ReturnType<typeof createCurrentUserActor>;

export const getCurrentUserActor = () => {
  return actorSystem.get<CurrentUserActor>("currentUser");
};
