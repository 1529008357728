import type { userState } from "@fscrypto/domain/";
import { useNavigate } from "@remix-run/react";
import { useInterpret } from "@xstate/react";
import { useState } from "react";
// import { createCurrentUserMachine } from "~/features/current-user";
import { useRunOnce } from "~/hooks/useRunOnce";
import { CurrentUser } from "@fscrypto/domain";
import { actorSystem } from "..";
import { featureFlags$$ } from "../feature-flags";
import { createNavigationMachine } from "../machines/navigation/navigation";
import { createUserStateMachine } from "../machines/user-state/user-state";
import { createVersionListenerActor } from "../machines/version-listener";
import { PlanSettings } from "@fscrypto/domain/plan-settings";
import { Profile } from "@fscrypto/domain/profile";
import { createCurrentUserActor } from "~/features/current-user/state/current-user";

const useInterpretMachines = (initialUserState: userState.UserState, user?: CurrentUser) => {
  const nav = useNavigate();
  const [navigate] = useState(() => createNavigationMachine(nav));
  const [userState] = useState(() => createUserStateMachine(initialUserState, user?.id));
  // const [currentUser] = useState(() => createCurrentUserMachine(user, userPlan, profile));
  const services = {
    // currentUser: useInterpret(currentUser).start(),
    navigate: useInterpret(navigate),
    userState: useInterpret(userState),
  };
  return services;
};

export const AppStateProvider: React.FC<
  React.PropsWithChildren<{
    initialUserState: userState.UserState;
    featureFlags: Record<string, string | boolean>;
    user?: CurrentUser;
    userPlan?: PlanSettings;
    profile?: Profile;
  }>
> = ({ children, initialUserState, featureFlags, user, userPlan, profile }) => {
  const { navigate, userState } = useInterpretMachines(initialUserState, user);
  useRunOnce(() => {
    createVersionListenerActor("versionListener", {});
    createCurrentUserActor("currentUser", { currentUser: user, userPlan, profile });
    featureFlags$$.next(featureFlags);
    actorSystem.bulkRegister({
      navigate,
      userState,
    });
  });
  return <>{children}</>;
};
