import { createMachine } from "xstate";
import type { OutputEvent as EpicEvent } from "./epic";
import { CurrentUser } from "@fscrypto/domain";
import { PlanSettings } from "@fscrypto/domain/plan-settings";
import { Profile } from "@fscrypto/domain/profile";
import { UserUpdate } from "@fscrypto/domain/user";

interface Context {
  currentUser?: CurrentUser;
  errors: null | Partial<Record<keyof CurrentUser, string>>;
  userPlan?: PlanSettings;
  profile?: Profile;
}

// A union of all events externally sent to the actor
type RefEvent =
  | { type: "CURRENT_USER.UPDATE_PROFILE"; payload: UserUpdate }
  | { type: "CURRENT_USER.SET_CURRENT_PROFILE_ID"; payload: { profileId: string } }
  | { type: "CURRENT_USER.SET_DEFAULT_WAREHOUSE"; payload: Profile["defaultWarehouseType"] };
type Event = RefEvent | EpicEvent;

// All dependencies necessary for initializing the actor
export interface Deps {
  currentUser?: CurrentUser;
  userPlan?: PlanSettings;
  profile?: Profile;
}

// They key pattern that these actors can be referenced by
// If only one actor of this type will exist, you can remove the -${string}
export type SystemKey = `currentUser`;

export const createCurrentUserMachine = (id: SystemKey, { currentUser, userPlan, profile }: Deps) => {
  return createMachine({
    id,
    tsTypes: {} as import("./machine.typegen").Typegen0,
    predictableActionArguments: true,
    schema: {
      context: {} as Context,
      events: {} as Event,
    },
    initial: "idle",
    context: {
      currentUser,
      errors: null,
      userPlan,
      profile,
    },
    states: {
      idle: {
        on: {
          "CURRENT_USER.SET_DEFAULT_WAREHOUSE": {
            actions: ["setDefaultWarehouse"],
            target: "updatingWarehouse",
          },
          "CURRENT_USER.UPDATE_PROFILE": {
            actions: ["updateUser"],
            target: "updatingUser",
          },
          "CURRENT_USER.SET_CURRENT_PROFILE_ID": {
            actions: ["setProfileId"],
          },
        },
      },
      updatingUser: {
        entry: ["updateUserEpic"],
        on: {
          "CURRENT_USER.EPIC.UPDATE_USER_SUCCESS": {
            target: "idle",
            actions: "toastSuccess",
          },
          "CURRENT_USER.EPIC.UPDATE_USER_FAILURE": {
            target: "idle",
            actions: "toastFailure",
          },
        },
      },
      updatingWarehouse: {
        entry: ["updateDefaultWarehouseEpic"],
        on: {
          "CURRENT_USER.EPIC.UPDATE_WAREHOUSE_SUCCESS": {
            target: "idle",
          },
          "CURRENT_USER.EPIC.UPDATE_WAREHOUSE_FAILURE": {
            target: "idle",
          },
        },
      },
    },
  });
};

export type Options = Parameters<ReturnType<typeof createCurrentUserMachine>["withConfig"]>["0"];
