import { assign } from "xstate";
import { createActor } from "~/state/actor";
import { type SystemKey, createVersionListenerMachine, type Options } from "./machine";
import { createEpic } from "./epic";
import { actorSystem } from "~/state";

export const createVersionListenerActor = createActor(
  { createMachine: createVersionListenerMachine, createEpic },
  ({ epic, eventBus }): Options => ({
    actions: {
      setVersion: assign({
        version: (_ctx, event) => event.payload.version,
      }),
      handleVersionChange: (context, event) => {
        eventBus.send({
          type: "TOAST.NOTIFY",
          notif: {
            title: "The App has been updated",
            type: "info",
            description: `Please refresh the page to get the latest version`,
            preventClose: true,
          },
        });
      },
    },
    guards: {
      isEmpty: (ctx) => !ctx.version,
      isDifferent: (ctx, event) => ctx.version !== event.payload.version,
    },
  }),
);

export type Actor = ReturnType<typeof createVersionListenerActor>;

export const getVersionListenerActor = (id: SystemKey) => {
  return actorSystem.get<Actor>(id);
};
